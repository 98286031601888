import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationSaveDto } from '../models/application-save-dto';
import { environment } from '../../environments/environment';
import { concatMap, Observable, of, throwError, timeout } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { IPrizeClaimSaveDto } from '../models/prize-claim-save-dto';
import { WinnerListItem } from '../models/winner-list-item';
import { RewardTag } from '@app/models/reward-tag';
import * as chance from 'chance';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly _httpClient = inject(HttpClient);
  private readonly _recaptchaV3Service = inject(ReCaptchaV3Service);

  /**
   * Submit Foxy club form
   * @param dto
   */
  public submit(dto: ApplicationSaveDto): Observable<{
    detail: string;
    nagroda: RewardTag;
    status: string;
    token: string;
  }> {
    const formData = new FormData();
    formData.append('imie', dto.firstname);
    formData.append('nazwisko', dto.lastname);
    formData.append('telefon', dto.mobile);
    formData.append('email', dto.email);
    formData.append('oswiadczenie', dto.consentAdult ? '1' : '0');
    formData.append('regulamin', dto.consentRegulations ? '1' : '0');
    formData.append('zgodadane', dto.consentClause ? '1' : '0');
    formData.append('komunikacja', dto.consentCommunication ? '1' : '0');
    formData.append('klauzula', dto.consentExclusion ? '1' : '0');
    formData.append('nr_paragonu', dto.proofOfPurchaseNumber);
    formData.append('data_paragonu', dto.dateOfPurchase);
    formData.append('plik_paragonu', dto.imageOfPurchase);
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptcha: string) => {
        formData.append('recaptcha', recaptcha);
        return this._httpClient.post<{
          detail: string;
          nagroda: RewardTag;
          status: string;
          token: string;
        }>(`${environment.api}/zgloszenie`, formData);
      }),
    );
  }

  /**
   * Submit prize claim form
   * @param dto
   */
  public submitPrizeClaim(dto: IPrizeClaimSaveDto): Observable<unknown> {
    return this._recaptchaV3Service.execute('importantAction').pipe(
      timeout({ first: 5000, with: () => throwError(() => 'timeout') }),
      concatMap((recaptcha: string) =>
        this._httpClient.post<unknown>(`${environment.api}/potwierdzenie`, {
          ...dto,
          recaptcha,
        }),
      ),
    );
  }
  // laureaci
  public getWinners(): Observable<WinnerListItem[]> {
    if (environment.development) {
      const _chance = chance.Chance();
      const buildTestData = (
        tydzien: string,
        dzien: string,
        main: boolean,
      ) => ({
        tydzien,
        dzien,
        nazwa: main ? 'Przelew 5000 PLN' : 'Voucher Decathlon 100 PLN',
        imie: _chance.first(),
        tel: _chance.string({ length: 3, pool: '0123456789' }),
      });

      return of([
        buildTestData('1', '2024-04-15', true),
        buildTestData('1', '2024-04-15', false),
        buildTestData('1', '2024-04-15', false),
        buildTestData('1', '2024-04-15', false),
        buildTestData('1', '2024-04-15', false),
        buildTestData('1', '2024-04-15', false),
        buildTestData('1', '2024-04-16', true),
        buildTestData('1', '2024-04-16', false),
        buildTestData('1', '2024-04-16', false),
        buildTestData('1', '2024-04-16', false),
        buildTestData('1', '2024-04-16', false),
        buildTestData('1', '2024-04-16', false),
        buildTestData('1', '2024-04-17', true),
        buildTestData('1', '2024-04-17', false),
        buildTestData('1', '2024-04-17', false),
        buildTestData('1', '2024-04-17', false),
        buildTestData('1', '2024-04-17', false),
        buildTestData('1', '2024-04-17', false),
        buildTestData('1', '2024-04-18', true),
        buildTestData('1', '2024-04-18', false),
        buildTestData('1', '2024-04-18', false),
        buildTestData('1', '2024-04-18', false),
        buildTestData('1', '2024-04-18', false),
        buildTestData('1', '2024-04-18', false),
        buildTestData('1', '2024-04-19', true),
        buildTestData('1', '2024-04-19', false),
        buildTestData('1', '2024-04-19', false),
        buildTestData('1', '2024-04-19', false),
        buildTestData('1', '2024-04-19', false),
        buildTestData('1', '2024-04-19', false),
        buildTestData('1', '2024-04-20', true),
        buildTestData('1', '2024-04-20', false),
        buildTestData('1', '2024-04-20', false),
        buildTestData('1', '2024-04-20', false),
        buildTestData('1', '2024-04-20', false),
        buildTestData('1', '2024-04-20', false),
        buildTestData('1', '2024-04-21', true),
        buildTestData('1', '2024-04-21', false),
        buildTestData('1', '2024-04-21', false),
        buildTestData('1', '2024-04-21', false),
        buildTestData('1', '2024-04-21', false),
        buildTestData('1', '2024-04-21', false),
        buildTestData('2', '2024-04-22', true),
        buildTestData('2', '2024-04-22', false),
        buildTestData('2', '2024-04-22', false),
        buildTestData('2', '2024-04-22', false),
        buildTestData('2', '2024-04-22', false),
        buildTestData('2', '2024-04-22', false),
        buildTestData('2', '2024-04-23', true),
        buildTestData('2', '2024-04-23', false),
        buildTestData('2', '2024-04-23', false),
        buildTestData('2', '2024-04-23', false),
        buildTestData('2', '2024-04-23', false),
        buildTestData('2', '2024-04-23', false),
      ]);
    }

    return this._httpClient.get<WinnerListItem[]>(
      `${environment.api}/laureaci`,
    );
  }
}
